<template>
  <v-container class="text-center">
    <v-card>
      
      

      <v-card-title class="justify-center">
        Joe Zugelter
      </v-card-title>
<v-avatar :height="200" :width="200">
  <v-img
        :src="require('../assets/profile_pic.png')"

        
        alt="A picture of Joe's face. Fun and possibly professional."
      />
      </v-avatar>
      <v-card-text>
        Software Engineer
      </v-card-text>


      <v-row class="justify-center justify-space-around pb-6" >
      <v-btn href="mailto: contact@joezugelter.com?subject=Hey Joe" >
        <v-icon dark>
          mdi-email
        </v-icon>
        Email
      </v-btn>

      <v-btn href="https://www.linkedin.com/in/joe-zugelter/" >
        <v-icon dark>
          mdi-linkedin
        </v-icon>
        LinkedIn
      </v-btn>

      <v-btn href="https://github.com/Zugie" >
        <v-icon dark>
          mdi-github
        </v-icon>
        GitHub
      </v-btn>

      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
